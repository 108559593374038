





































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { Faturas } from '@/plugins/api';
import { BTable, BvTableCtxObject } from 'bootstrap-vue';
import get from 'bootstrap-vue/src/utils/get';
import { eventOff, eventOn } from 'vue-context/src/js/utils';
import FaturasExportar from './components/Exportar.vue';
import FaturasContext from './components/ContextMenu.vue';
import { columns, columnsFilter } from './components/tabela';

@Component({
  components: {
    FaturasExportar,
    FaturasContext,
  },
})
export default class FaturamentosIndex extends Vue {
  @Ref() readonly table!: BTable;
  @Ref() readonly context!: FaturasContext;
  public loading = false;
  public currentPage = 1;
  public totalRows = 1;
  public perPage = 50;
  public sortBy = 'data_criacao';
  public sortDesc = true;

  public columns = columns;
  public soma = {} as IFatura;
  public getField = get;

  created(): void {
    this.$store.commit('SET_TABELA', 'Faturas');
    this.$store.commit('SET_COLUNAS', columnsFilter);
  }

  mounted(): void {
    eventOn(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$on('filtros', this.refresh);
  }

  destroy(): void {
    eventOff(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$off('filtros', this.refresh);
  }

  get filtros(): IFiltroValues[] {
    return this.$store.getters.filtrosSelecionados;
  }

  provider(
    context: BvTableCtxObject,
    callback: (data: IFatura[]) => void
  ): void {
    this.loading = true;
    const request = {
      params: {
        page: context.currentPage,
        size: context.perPage,
        sortBy: context.sortBy,
        sortDesc: context.sortDesc ? 'DESC' : 'ASC',
        filters: this.filtros,
      },
    };

    Faturas.get(request)
      .then((response: GetFaturasResponse) => {
        this.totalRows = response.metadata.total;

        const faturamentos = response.data;
        faturamentos.forEach((fatura) => {
          fatura._rowVariant = Vue.filter('FaturaCor')(fatura.situacao);
        });
        this.soma = faturamentos.reduce((result, item) => {
          const current = JSON.parse(JSON.stringify(result ?? {})) as IFatura;

          current.valor =
            parseFloat(current.valor.toString() ?? '0') +
            parseFloat(item.valor.toString() ?? '0');
          current.simulacoes.valor_adesao =
            parseFloat(current.simulacoes?.valor_adesao?.toString() ?? '0') +
            parseFloat(item.simulacoes?.valor_adesao?.toString() ?? '0');

          return current;
        });
        callback(faturamentos);
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public refresh(): void {
    this.table.refresh();
  }

  public clearSelection(): void {
    this.table.clearSelected();
  }

  public contextMenu(item: IFatura, data: number, event: MouseEvent): void {
    event.preventDefault();
    this.table.selectRow(data);
    this.context.open(event, item);
  }
}
